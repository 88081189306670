<template>
        <v-expansion-panel>
            <v-expansion-panel-header v-if="warehouse" class="mt-2">
                ALMACÉN : {{warehouse.ID_Warehouse}}
                <template v-slot:actions>
                    <v-icon color="error" @click="$store.dispatch('deleteWindowByUuid',{uuid : win.uuid})">
                        mdi-close
                    </v-icon>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-card>
                    <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
                        <v-tabs-slider color="red"></v-tabs-slider>
                        <v-tab href="#tab-1">
                            LOTES Y GRUPOS
                            <v-icon>mdi-plus</v-icon>
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <v-tab-item value="tab-1">
                            <v-card flat>
                                <ManageBatchAndAccountingGroups :data="warehouse" :win="win"></ManageBatchAndAccountingGroups>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-expansion-panel-content>
        </v-expansion-panel>
</template>

<script>
    import ManageBatchAndAccountingGroups from "../tabs/ManageBatchAndAccountingGroups";

    export default {
        name: "ManageWarehouse",
        props: ['win'],
        data() {
            return {
                tab:null,
                warehouse: this.win.meta.warehouse
            }
        },

        components: {
            ManageBatchAndAccountingGroups
        },
        methods: {
            reloadData(payload) {
                this.$emit('reloadData', payload)
            }
        }

    }
</script>

<style scoped>

</style>
